import * as actionTypes from './types';
import * as authService from '../../service/auth';
import { getAccountFromToken } from '../../service/auth/jwt.service';
import { setupTokenRefresh } from '../../service/api';
export const login =
  ({ email, password }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING
      });
      try {
        const { status, data } = await authService.login({ email, password });

        if (status === 200) {
          data.userName = getAccountFromToken(data.access_token).preferred_username;
          const auth_state = {
            current: data,
            isLoggedIn: true,
            isLoading: false,
            isSuccess: true
          };
          window.localStorage.setItem('auth', JSON.stringify(auth_state));
          window.localStorage.removeItem('isLogout');

          setupTokenRefresh();

          dispatch({
            type: actionTypes.REQUEST_SUCCESS,
            payload: data
          });
        } else {
          console.error('Login failed. Status:', status);
          dispatch({
            type: actionTypes.REQUEST_FAILED
          });
        }
      } catch (error) {
        console.error('Error during login:', error);
        dispatch({
          type: actionTypes.REQUEST_FAILED
        });
      }
    };

let isLoggingOut = false;
let logoutMessageDisplayed = false; // Prevent duplicate notifications

export const logout = () => async (dispatch) => {
  try {
    await authService.logout(); // Optional: API call to log out
    await localStorage.removeItem('auth');
    dispatch({
      type: actionTypes.LOGOUT_SUCCESS
    });
  } catch (error) {
    console.error('Error during logout:', error);
    dispatch({
      type: actionTypes.LOGOUT_FAILED
    });
  }
};

export const fetchUser = () => async (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_LOADING });
  try {
    const { status, data } = await authService.getMe();
    if (status === 200) {
      const userInfo = {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        roles: data.roles,
        userName: data.userName
      };

      // Merge the updated fields into local storage, if needed
      const existingAuthState = JSON.parse(localStorage.getItem('auth')) || {};
      const updatedAuthState = {
        ...existingAuthState,
        current: {
          ...existingAuthState.current,
          ...userInfo
        },
        isLoggedIn: true,
        isLoading: false,
        isSuccess: true
      };

      localStorage.setItem('auth', JSON.stringify(updatedAuthState));
      dispatch({ type: actionTypes.REQUEST_SUCCESS, payload: userInfo });
    } else {
      dispatch({ type: actionTypes.REQUEST_FAILED });
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    dispatch({ type: actionTypes.REQUEST_FAILED });
  }
};

