import logo from '../../../assets/image/logoSeeWardWhite.png';
import logoSmall from '../../../assets/image/white.png';
import profile from '../../../assets/image/empty-user.jpg';
import {
  ChevronFirst,
  ChevronLast,
  MoreVertical,
  Settings,
  UserPen,
  LogOut,
  LayoutDashboard,
  StickyNote,
  Bug,
  Server,
  Flag,
  Link as LinkIcon,
  UsersRound,
  Bell,
  Route,
  Skull
} from 'lucide-react';
import {
  createContext,
  useContext,
  useState
} from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Dropdown, Modal } from 'antd';
import { logout as logoutAction } from '../../../redux/auth/actions';
import CVE from '../../../assets/icons/cve.svg';
import { selectAuth } from '../../../redux/auth/selectors';

const SidebarContext = createContext({ expanded: false });

export default function Sidebar2() {
  const [expanded, setExpanded] = useState(
    localStorage.getItem('sidebarCollapsed') !== null
      ? JSON.parse(localStorage.getItem('sidebarCollapsed')!)
      : true
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector(selectAuth);
  const userEmail = current.email;
  const username = `${current.first_name || ''} ${current.last_name || ''}`;

  const handleToggle = () => {
    setExpanded(!expanded);
    localStorage.setItem('sidebarCollapsed', JSON.stringify(!expanded));
  };

  const logoutHandler = () => {
    Modal.confirm({
      title: 'Are you sure you want to log out?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        await asyncLogout(); // Await the logout completion
        navigate('/login'); // Navigate only after successful logout
      }
    });
  };

  async function asyncLogout() {
    try {
      await dispatch<any>(logoutAction());
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  const menuItems = [
    {
      key: '1',
      icon: <Bell size={20} />,
      text: 'Notification',
      link: '/notification',
      alert: true
    }, {
      type: 'divider',
      label: 'Menu'
    }, {
      key: '1',
      icon: <LayoutDashboard size={20} />,
      text: 'Dashboard',
      link: '/'
    }, {
      key: '1',
      icon: <Route size={20} />,
      text: 'Action plan',
      link: '/action-plan'
    }, {
      key: '2',
      icon: <StickyNote size={20} />,
      text: 'Projects',
      link: '/project'
    }, {
      key: '3',
      icon: <Bug size={20} />,
      text: 'Vulnerability',
      link: '/vulnerability',
      subPaths: ['/vulnerability/create', '/vulnerability/edit', '/vulnerability/detail']
    }, {
      key: '4',
      icon: <Server size={20} />,
      text: 'Asset',
      link: '/asset'
    }, {
      key: '4',
      icon: <Skull size={20} />,
      text: 'Product EOL',
      link: '/product-eol'
    },{
      key: '5',
      icon: <Flag size={20} />,
      text: 'Reporting',
      link: '/report'
    }, {
      key: '6',
      icon: <LinkIcon size={20} />,
      text: 'Connector',
      link: '/connectors'
    }, { type: 'divider', label: 'Advanced' }, {
      key: '7',
      icon: <img src={CVE} width={20} />,
      text: 'CVE',
      link: '/vulnerability/cve'
    }, {
      key: '8',
      icon: <UsersRound size={20} />,
      text: 'Users',
      link: '/user'
    }, {
      key: '9',
      icon: <Settings size={20} />,
      text: 'Settings',
      link: '/settings'
    }
  ];

  const dropdownItems: any = [
    {
      key: '1',
      label: (
        <Link
          to="/profile"
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <div className="flex">
            <img src={profile} className="w-10 h-10 rounded-md" />
            <div
              className="flex justify-between items-center overflow-hidden transition-all w-52 ml-3"
            >
              <div className="leading-4">
                <h4
                  className="font-semibold text-black"
                >{username}</h4>
                <span
                  className="text-xs text-black"
                >{userEmail}</span>
              </div>
            </div>
          </div>
        </Link>
      )
    }, { type: 'divider' }, {
      key: '2',
      label: (
        <Link
          to="/profile"
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <UserPen width={18} />
          My Profile
        </Link>
      )
    }, {
      key: '3',
      label: (
        <Link
          to="/settings"
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <Settings width={18} />
          Account Settings
        </Link>
      )
    }, { type: 'divider' }, {
      key: '4',
      onClick: logoutHandler,
      label: (
        <button
          className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
        >
          <LogOut width={18} />
          Log Out
        </button>
      )
    }
  ];

  return (
    <>
      <aside className="h-screen bg-[#1b1d1f]">
        <nav className="h-full flex flex-col border-r shadow-sm">
          {!expanded ? (
            <div>
              <img
                src={logoSmall}
                className="overflow-hidden transition-all w-10 m-4 -mb-2"
              />
            </div>
          ) : (
            <div />
          )}
          <div
            className="p-4 pb-2 flex justify-between items-center"
          >
            <img
              src={logo}
              className={`overflow-hidden transition-all ${expanded ? 'w-44' : 'w-0'}`} 
            />
            <button
              onClick={() => handleToggle()}
              className="p-1.5 rounded-lg text-white bg-[#272b31] hover:bg-[#33383e]"
            >
              {expanded ? <ChevronFirst /> : <ChevronLast />}
            </button>
          </div>

          <SidebarContext.Provider value={{ expanded }}>
            <ul className="flex-1 px-3 mt-5">
              {menuItems.map((item, index) => {
                if (item.type === 'divider') {
                  return (
                    <div key={index + '-divider'}>
                      <Divider
                        className='bg-[#25272b]' 
                      />
                      <div
                        className={`mb-2 ${expanded ? 'block' : 'hidden'}`}
                      >
                        <span
                          className='text-[#a3a5a6] uppercase'
                        >{item.label}</span>
                      </div>
                    </div>
                  );
                }
                return (
                  <SidebarItem
                    key={index + '-item'}
                    icon={item.icon}
                    text={item.text}
                    link={item.link}
                    alert={item.alert}
                    // disabled={item.disabled?}
                    active={
                      location.pathname === item.link ||
                      (item.subPaths && item.subPaths.some((path) => location.pathname.startsWith(path)))
                    }
                    navigate={navigate}
                  />
                );
              })}
            </ul>
          </SidebarContext.Provider>
          <Dropdown
            menu={{ items: dropdownItems }}
            placement="topRight"
          >
            <div
              className="border-t border-[#25272b] flex p-3 cursor-pointer"
              onClick={(e) => e.preventDefault()}
            >
              <img
                src={profile}
                className="w-10 h-10 rounded-md" 
              />
              <div
                className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}
              >
                <div className="leading-4">
                  <h4
                    className="font-semibold text-white"
                  >{username}</h4>
                  <span
                    className="text-xs text-[#a3a5a6]"
                  >{userEmail}</span>
                </div>
                <MoreVertical
                  size={20}
                  className="text-white"
                />
              </div>
            </div>
          </Dropdown>
        </nav>
      </aside>
    </>
  );
}
export function SidebarItem({ icon, text, link, active, alert, navigate, disabled }: any) {
  const { expanded } = useContext(SidebarContext);

  const handleClick = () => {
    if (!disabled) {
      navigate(link);
    }
  };

  return (

    <div className="flex items-center">
      <li
        onClick={handleClick}
        className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md transition-colors group ${disabled
          ? 'cursor-not-allowed text-[#6e6f6f]'
          : active
            ? 'text-white bg-[#272b31] cursor-pointer'
            : 'hover:text-white text-[#a3a5a6] cursor-pointer'
        }`}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}
        >
          {text}
        </span>
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-red-600 ${expanded ? '' : 'top-2'}`}
          ></div>
        )}

        {!expanded && (
          <div
            className={`z-99999 absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 ${disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {text}
          </div>
        )}
      </li>
    </div>
  );
}
