import { AUTH_BASE_URL } from '../../config/serverApiConfig';

import axios from 'axios';
import errorHandler from '../../request/errorHandler';
import successHandler from '../../request/successHandler';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../redux/auth/selectors';

export const login = async ({ email, password }: { email: string; password: string }) => {
  try {
    const response = await axios.post(
      AUTH_BASE_URL + 'login',
      { email, password }
    );

    const { status, data } = response;

    console.log('--------- data ', data);

    response.data = data;
    console.log('response data ', response);
    successHandler(
      { response: response.data, status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return { status, data };
  } catch (error) {
    console.log('Errorrr , ', error);
    return errorHandler(error);
  }
};

export const logout = async () => {
  const user = window.localStorage.getItem('auth');
  const tmpAuth = user ? JSON.parse(user).current : null;

  if (!tmpAuth) {
    console.warn('No user session found to log out.');
    return; // Exit if no user session exists
  }

  try {
    // Make an API call to log out if needed
    const response = await axios.delete(
      AUTH_BASE_URL + 'logout', {
        headers: {
          Authorization: `Bearer ${tmpAuth.access_token}`
        }
      });

    if (!response || !response.data) {
      throw new Error('Logout API response is invalid.');
    }
  } catch (error: any) {
    console.error('Error during logout:', error.message || error);
    throw error;
  }
};

export const getMe = async () => {
  try {
    const user = window.localStorage.getItem('auth');
    const tmpAuth = user ? JSON.parse(user).current : null;
    const response = await axios.get(AUTH_BASE_URL + 'me', {
      headers: {
        Authorization: `Bearer ${tmpAuth.access_token}`
      }
    });
    if (!response || !response.data) {
      throw new Error('Get me API response is invalid.');
    }
    return {
      data: response.data,
      status: 200
    };
  } catch (error) {
    return errorHandler(error);
  }
};