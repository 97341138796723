import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectIsLoggedIn, selectUserRoles } from './redux/auth/selectors';
import { Button, Result } from 'antd';
import { LockKeyhole } from 'lucide-react';
import forbidden from '../src/assets/icons/403.svg';

const RoleProtectedRoute = ({ children, requiredRoles }: any) => {
  const userRoles = useSelector(selectUserRoles);

  if (requiredRoles && requiredRoles.length > 0) {
    const hasRequiredRole = requiredRoles.some((role: any) => userRoles.includes(role));
    if (!hasRequiredRole) {
      return <div>
        <Result
          icon={<div className='flex justify-center'>
            <img src={forbidden} alt="" className='w-[250px]' />
          </div>}
          title="403 Forbidden"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary" href="/">Back Home</Button>}
        />
      </div>;
    }
  }

  return <>{children}</>;
};

export default RoleProtectedRoute;
