import { Suspense, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import { App as AntdApp } from 'antd';

import store from './redux/store';
import AppContent from './AppContent';
import Loading from './components/UI/loading/Loading';
import { fetchUser } from './redux/auth/actions';
import { selectIsLoggedIn } from './redux/auth/selectors';
import { logout } from './service/auth';

function AppInitializer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (auth) {
      // Attempt to fetch user if token is present
      dispatch<any>((dispatch: any) => {
        dispatch(fetchUser()).finally(() => setInitializing(false));
      });
    } else {
      logout();
      navigate('/login');
      console.log('User is not logged in and token is not present, so go back to the login page');

      setInitializing(false);
    }
  }, [dispatch]);

  if (initializing) return <Loading />;

  return <AppContent />;
}

function App() {
  return (
    <Provider store={store}>
      <AntdApp>
        <ThemeProvider>
          <AppInitializer />
        </ThemeProvider>
      </AntdApp>
    </Provider>
  );
}

export default App;
