import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import DefaultLayout from './components/layout/DefaultLayout';
import LoginPage from './pages/login/LoginPage';
import RoleProtectedRoute from './RoleProtectedRoute';
import routes from './routes';

function AppContent() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route element={<DefaultLayout />}>
        {routes.map((r, i) => (
          <Route
            key={i}
            path={r.path}
            element={
              <RoleProtectedRoute requiredRoles={r.requiredRoles}>
                <Suspense fallback="loading">
                  <r.component />
                </Suspense>
              </RoleProtectedRoute>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default AppContent;
