import * as actionTypes from './types';

const INITIAL_STATE = {
  current: {}, // Stores authenticated user details
  isLoggedIn: false, // Tracks whether the user is logged in
  isLoading: false, // Tracks if an API request is in progress
  isSuccess: false // Tracks if the last operation was successful
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoggedIn: false
      };

    case actionTypes.REQUEST_FAILED:
      return {
        ...INITIAL_STATE,
        isSuccess: false
      };

    case actionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current, // Retain the existing fields
          email: action.payload.email,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          roles: action.payload.roles,
          userName: action.payload.userName
        },
        isLoggedIn: true,
        isLoading: false,
        isSuccess: true
      };

    case actionTypes.REGISTER_SUCCESS:
      return {
        ...INITIAL_STATE,
        isSuccess: true
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        isLoggedIn: false
      };

    default:
      return state;
  }
};

export default authReducer;
