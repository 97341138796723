import * as actionTypes from './types';

const INITIAL_KEY_STATE = {
  result: null,
  current: null,
  isLoading: false,
  isSuccess: false
};

const INITIAL_STATE = {
  current: {
    result: null
  },
  list: {
    search: '',
    result: {
      items: [],
      pagination: {
        current: 1,
        pageIndex: 1,
        pageSize: 100,
        total: 1,
        showSizeChanger: false
      },
      stats: {}
    },
    isLoading: false,
    isSuccess: false
  },
  visibility: {
   
  },
  filter: {

  },
  create: INITIAL_KEY_STATE,
  update: INITIAL_KEY_STATE,
  delete: INITIAL_KEY_STATE,
  read: INITIAL_KEY_STATE,
  search: { ...INITIAL_KEY_STATE, result: [] }
};

const ProductEOLReducer = (state = INITIAL_STATE, action) => {
  const { payload, keyState } = action;
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return INITIAL_STATE;
    case actionTypes.CURRENT_ITEM:
      return {
        ...state,
        current: {
          result: payload
        }
      };
    case actionTypes.REQUEST_LOADING:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: true
        }
      };
    case actionTypes.REQUEST_FAILED:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: false,
          isSuccess: false
        }
      };
    case actionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        [keyState]: {
          result: payload,
          isLoading: false,
          isSuccess: true
        }
      };
    case actionTypes.CURRENT_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_KEY_STATE,
          current: payload
        }
      };
    case actionTypes.RESET_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_STATE[keyState]
        }
      };
    case actionTypes.RESET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          result: {
            ...state.list.result,
            items: [] // Set items array to an empty array
          }
        }
      };
    case actionTypes.UPDATE_CURRENT_ITEM_FIELD:
      const { fieldName, value } = action.payload;
      return {
        ...state,
        current: {
          ...state.current,
          result: {
            ...state.current.result,
            [fieldName]: value
          }
        }
      };

    case actionTypes.UPDATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload // Assuming payload is the updated filter object
        }
      };
    case actionTypes.SET_FILTER_CHANGED_FLAG:
      return {
        ...state,
        filter: {
          ...state.filter,
          enable: action.payload // true if filter has changed, false otherwise
        }
      };
    default:
      return state;
  }
};

export default ProductEOLReducer;
