export const RESET_STATE = 'PRODUCT_EOL_RESET_STATE';
export const CURRENT_ITEM = 'PRODUCT_EOL_CURRENT_ITEM';

export const REQUEST_LOADING = 'PRODUCT_EOL_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'PRODUCT_EOL_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'PRODUCT_EOL_REQUEST_FAILED';

export const CURRENT_ACTION = 'PRODUCT_EOL_CURRENT_ACTION';
export const RESET_ACTION = 'PRODUCT_EOL_RESET_ACTION';

export const RESET_LIST = 'PRODUCT_EOL_RESET_LIST';

export const UPDATE_CURRENT_ITEM_FIELD = 'UPDATE_CURRENT_ITEM_FIELD';

export const UPDATE_FILTER = 'PRODUCT_EOL_UPDATE_FILTER';

export const SET_FILTER_CHANGED_FLAG = 'SET_FILTER_CHANGED_FLAG';