import { combineReducers } from 'redux';

import { reducer as authReducer } from './auth';
// import { reducer as crudReducer } from './crud';
// import { reducer as erpReducer } from './erp';
// import { reducer as settingsReducer } from './settings';
import { reducer as translateReducer } from './translate';
import { reducer as vulnerabilityReducer } from './vulnerability';
import { reducer as CveReducer } from './cve';
import { reducer as assetReducer } from './asset';
import { reducer as projectReducer } from './project';
import { reducer as reportReducer } from './report';
import { reducer as reportActionPlan } from './actionPlan';
import { reducer as reportUsers } from './users';
import { reducer as productEOL } from './productEndOfLife';

// Combine all reducers.

const rootReducer = combineReducers({
  auth: authReducer,
  vulnerability: vulnerabilityReducer,
  asset: assetReducer,
  project: projectReducer,
  // crud: crudReducer,
  // erp: erpReducer,
  // settings: settingsReducer,
  user: reportUsers,
  translate: translateReducer,
  report: reportReducer,
  actionPlan: reportActionPlan,
  cve: CveReducer,
  smtp: vulnerabilityReducer.smtpConfig,
  ProductEOL: productEOL
});

export default rootReducer;
